import { isMobile } from './helpers/isMobile';

/**
 * Represents a SpaceReductionHandler class that handles the space
 * between carousel and content below on PDP.
 */
export class SpaceReductionHandler {
  // Checks if the space reduction element is present on the page
  static isOnPage() {
    return $('.js-accordion-container').length > 0;
  }

  constructor() {
    this.init();
  }

  /**
   * Initializes the space reduction functionality by binding events and reducing space initially.
   */
  init() {
    this.bindEvents();
    this.handleSpaceReduction();
  }

  handleSpaceReduction() {
    // eslint-disable-next-line no-negated-condition
    if (!isMobile()) {
      this.applyNegativeMargin();
    } else {
      this.resetSpaceReduction();
    }
  }

  applyNegativeMargin() {
    const $carouselContainer = $('.js-carousel-container');
    const $accordionContainer = $('.js-accordion-container');

    if ($carouselContainer.length && $accordionContainer.length) {
      const carouselContainerBottom = $carouselContainer.offset().top + $carouselContainer.outerHeight();
      const accordionContainerTop = $accordionContainer.offset().top;
      const negativeMargin = carouselContainerBottom - accordionContainerTop;
      $accordionContainer.css('margin-top', `${negativeMargin + 20}px`);
    }
  }

  resetSpaceReduction() {
    const $accordionContainer = $('.js-accordion-container');

    if ($accordionContainer.length) {
      // Reset margin to default
      $accordionContainer.css('margin-top', '');
    }
  }

  /**
   * Binds the window resize event to adjust the space dynamically when the window is resized.
   */
  bindEvents() {
    $(window).on('resize', () => {
      this.handleSpaceReduction();
    });
  }
}
