import { Carousel } from './carousel';

/**
 * IntroCarousel is carousel component displayed in full width
 */
export class IntroCarousel extends Carousel {
  /**
   * css selector of carousel container
   * @static
   */
  static selector = '.js-intro-carousel';

  /**
   * Checks if container node is present in the dom
   * @function isOnPage
   * @returns {Boolean}
   * @static
   */
  static isOnPage() {
    return document.querySelector(IntroCarousel.selector);
  }

  constructor() {
    super();
    this.carousel = new Map();
    super.init(this, IntroCarousel.selector);
  }

  updateTabIndex(swiper) {
    $('.intro-carousel-link').attr('tabindex', '-1');
    $('.btn.intro-carousel-btn').attr('tabindex', '-1');
    const activeSlide = swiper.slides[swiper.activeIndex];
    $(activeSlide).find('.btn.intro-carousel-btn').attr('tabindex', '0');
    $(activeSlide).find('.intro-carousel-link').first().attr('tabindex', '0');
  }

  /**
   * Generate config depending of the number of the slides in carousel by extending the default config
   * @param {Node} $carousel - Carousel DOM Node
   * @returns {Object} extended config
   */
  generateConfig($carousel) {
    if (super.hasSingleSlide($carousel)) {
      return super.extendConfig({
        pagination: false,
        loop: false,
        autoplay: false
      });
    }

    const that = this;

    return super.extendConfig({
      autoplay: super.toggleAutoplay($carousel),
      pagination: {
        el: '.js-carousel-pagination-intro',
        clickable: true,
        type: 'bullets'
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      on: {
        init: function () {
          that.updateTabIndex(this);
        },
        slideChange: function () {
          that.updateTabIndex(this);
        },
      },
    });
  }
}